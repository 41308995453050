var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin-dashboard-layout',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.users,"items-per-page":5},on:{"click:row":_vm.handleClickRow},scopedSlots:_vm._u([{key:"item.intervals",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.patient && item.patient.intervals_count) ? item.patient.intervals_count : 0)+" ")]}},{key:"item.workouts",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.patient && item.patient.workouts_count) ? item.patient.workouts_count : 0)+" ")]}},{key:"item.xrays",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.patient && item.patient.xrays_count) ? item.patient.xrays_count : 0)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }